import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { Plugin } from "vue";

import useUserUniqId from '~/composables/useUserUniqId';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $analytics: typeof amplitude;
  }
}

const analyticsPlugin: Plugin = (app) => {
  const userId = useUserUniqId();

  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, { defaultTracking: true, userId })
  amplitude.add(sessionReplayPlugin())
  app.config.globalProperties.$analytics = amplitude;
};

export default analyticsPlugin;
