import axios from 'axios'

export default class Logger {
  constructor(options = {}) {
    this._tags = options.tags || {}
    this._handlers = {
      console: this.consoleHandler.bind(this),
      logRocket: this.logRocketHandler.bind(this),
      api: this.apiHandler.bind(this)
    }
  }

  set logRocket(logRocket) { this._logRocket = logRocket }

  invoke(level, message) {
    Object.entries(this._handlers).forEach(([name, func]) => {
      func(name, level, message)
    })
  }

  logRocketHandler(name, level, message) {
    if (!this._logRocket) return

    this._logRocket[level](...message)
  }

  apiHandler(name, level, message) {
    axios.post(
      `/api/v1/logger`,
      {
        tags: this._tags,
        level,
        message
      },
    )
  }

  consoleHandler(name, level, ...message) {
    if (Object.keys(this._tags).length) {
      console[level](this._tags, ...message)
    } else {
      console[level](...message)
    }
  }

  debug(...args) {
    this.invoke('debug', ...args)
  }
  info(...args) {
    this.invoke('info', ...args)
  }
  warn(...args) {
    this.invoke('warn', ...args)
  }
  error(...args) {
    this.invoke('error', ...args)
  }
}
