import 'sweetalert2/dist/sweetalert2.min.css';
import '~/stylesheets/sweetalert.scss'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notifications from 'notiwind'
import { configureCompat, createApp } from 'vue';
import CountryFlag from 'vue-country-flag-next'
import VueSweetalert2 from 'vue-sweetalert2';
import SmartTable from 'vuejs-smart-table'

import ViewaboLogo from '~/components/viewabo_logo.vue'
import analyticsPlugin from '~/plugins/analytics.ts'

import Logger from './logger'

// TODO: remove
export const compatConfig = {
  MODE: 3,
  WATCH_ARRAY: false,
  RENDER_FUNCTION: false,
  INSTANCE_CHILDREN: true,
  // COMPONENT_V_MODEL: false,
}

configureCompat(compatConfig)

const mountApp = (component, node, options) => {
  const props = JSON.parse(node.getAttribute("data"));

  // Render component with props
  const app = createApp(component, { ...props })

  const provide = JSON.parse(node.getAttribute("data-provide") || "{}")
  Object.entries(provide).forEach(([key, value]) => {
    app.provide(key, value)
  })

  const logger = new Logger({tags: provide.log_tags})
  app.provide('logger', logger)
  app.provide('railsEnv', import.meta.env.VITE_RAILS_ENV)
  // registry plugins
  if (options?.analytics) {
    app.use(analyticsPlugin)
  }
  app.use(VueSweetalert2)
  app.use(SmartTable)
  app.use(Notifications)
  app.component('font-awesome-icon', FontAwesomeIcon)
  app.component("country-flag", CountryFlag)
  app.component("ViewaboLogo", ViewaboLogo)

  app.mount(node);
}

export const mountComponent = (component, options) => {
  const stringUnderscore = (str) => str.replace(/([a-z])([A-Z])/g, (_, first, second) => `${first}_${second.toLowerCase()}` ).toLowerCase()
  const name = stringUnderscore(component.name)
  // Get the properties BEFORE the app is instantiated
  const nodes = document.querySelectorAll(`#${name}_pack`);
  nodes.forEach((ele) => {
    mountApp(component.component, ele, options)
  })
}

// deprecation
export function mountVueComponent(component, options) {
  document.addEventListener("DOMContentLoaded", () => {
    mountComponent(component, options)
  });
}

export const mountVueComponents = (components, options = { analytics: true }) => {
  Object.entries(components).forEach(([name, component]) => {
    mountVueComponent({ name: name, component: component}, options)
  })
}
